export const electronicSignatureDocumentsQueryKeys = {
  fetch_paginated_document: (queryParams = {}) =>
    ['fetch_paginated_document', queryParams] as const,
  fetch_document_proof_signature: (queryParams = {}) =>
    ['fetch_document_proof_signature', queryParams] as const,
  fetch_document_signature: (queryParams = {}) =>
    ['fetch_document_signature', queryParams] as const,
  post_document_reminder: () => ['post_document_reminder'] as const,
  post_document_signature: () => ['post_document_signature'] as const,
  delete_document_signature: () => ['delete_document_signature'] as const
}
