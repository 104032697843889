import Immutable from 'immutable'
import { filterActions } from 'redux-ignore'
import { call } from 'redux-saga/effects'

import {
  FETCH_INTEGRATIONS,
  FETCH_INTEGRATIONS_SUCCEEDED,
  FETCH_INTEGRATIONS_FAILED,
  UPDATE_INTEGRATION,
  UPDATE_INTEGRATION_SUCCEEDED,
  UPDATE_INTEGRATION_FAILED,
  CREATE_INTEGRATION,
  CREATE_INTEGRATION_SUCCEEDED,
  CREATE_INTEGRATION_FAILED
} from '../../actionTypes'
import { call as callAPI } from '../../infra/http'
import {
  mergeRecords,
  updateRecord,
  listGroupToMapByForeignKey
} from '../../services/immutableUtils'
import { fetchSagaEntity } from '../../services/sagaUtils'

// ------------------------------------
// Actions
// ------------------------------------

export const fetchIntegrations = (accountId, params, resolve, reject) => ({
  type: FETCH_INTEGRATIONS,
  accountId,
  params,
  resolve,
  reject
})

export const updateIntegration = (
  integrationId,
  integrationData,
  resolve,
  reject
) => ({
  type: UPDATE_INTEGRATION,
  integrationId,
  integrationData,
  resolve,
  reject
})

export const createIntegration = (loginData, resolve, reject) => ({
  type: CREATE_INTEGRATION,
  loginData,
  resolve,
  reject
})

// ------------------------------------
// Sagas
// ------------------------------------

const integrationsSagaEntity = {
  success: (data, accountId) => ({
    type: FETCH_INTEGRATIONS_SUCCEEDED,
    data,
    accountId
  }),
  failure: (error, accountId) => ({
    type: FETCH_INTEGRATIONS_FAILED,
    error,
    accountId
  }),

  fetchAPI: (_, options) => callAPI(`/pos/integrations`, options)
}

export function* doFetchIntegrations({ accountId, params, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    integrationsSagaEntity,
    accountId,
    params,
    undefined,
    resolve,
    reject
  )
}

const updateIntegrationSagaEntity = {
  success: (data, integrationId) => ({
    type: UPDATE_INTEGRATION_SUCCEEDED,
    data,
    integrationId
  }),
  failure: (error, integrationId) => ({
    type: UPDATE_INTEGRATION_FAILED,
    error,
    integrationId
  }),

  fetchAPI: (integrationId, options) =>
    callAPI(`/integrations/${integrationId}`, { method: 'PUT', ...options })
}

export function* doUpdateIntegration({
  integrationId,
  integrationData,
  resolve,
  reject
}) {
  yield call(
    fetchSagaEntity,
    updateIntegrationSagaEntity,
    integrationId,
    null,
    integrationData,
    resolve,
    reject
  )
}

const createIntegrationSagaEntity = {
  success: data => ({ type: CREATE_INTEGRATION_SUCCEEDED, data }),
  failure: error => ({ type: CREATE_INTEGRATION_FAILED, error }),

  fetchAPI: (id, options) =>
    callAPI('/integrations', { method: 'POST', ...options })
}

export function* docreateIntegration({ loginData, resolve, reject }) {
  yield call(
    fetchSagaEntity,
    createIntegrationSagaEntity,
    null,
    null,
    loginData,
    resolve,
    reject
  )
}

// ------------------------------------
// Models
// ------------------------------------

export const Integration = Immutable.Record({
  id: null,
  account_id: null,
  source: null,
  enabled: null,
  last_fetched_at: null,
  restaurant_mapping: null,
  last_event_if_error: null,
  server_url: null
})

// ------------------------------------
// Reducers
// ------------------------------------

const initialState = Immutable.Map({
  data: Immutable.Map(),
  meta: Immutable.Map({ loading: false }),
  relations: Immutable.Map()
})

function integrations(state = initialState, action = {}) {
  // @ts-ignore migration from js(x) to ts(x)
  switch (action.type) {
    case UPDATE_INTEGRATION:
    case CREATE_INTEGRATION:
    case FETCH_INTEGRATIONS: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: true })
    }
    case UPDATE_INTEGRATION_FAILED:
    case CREATE_INTEGRATION_FAILED:
    case FETCH_INTEGRATIONS_FAILED: {
      // @ts-ignore migration from js(x) to ts(x)
      return state.mergeDeepIn(['meta'], { loading: false, success: false })
    }
    case FETCH_INTEGRATIONS_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const integrations = Immutable.fromJS(action.data)
      const integrationsGroupByAccount = listGroupToMapByForeignKey(
        integrations,
        'account_id'
      )

      return mergeRecords(state, Integration, integrations)
        .mergeDeepIn(['relations', 'account_id'], integrationsGroupByAccount)
        .mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
    }
    case CREATE_INTEGRATION_SUCCEEDED:
    case UPDATE_INTEGRATION_SUCCEEDED: {
      // @ts-ignore migration from js(x) to ts(x)
      const integration = Immutable.fromJS(action.data)

      return updateRecord(state, Integration, integration)
        .updateIn(
          ['relations', 'account_id', integration.get('account_id')],
          // @ts-ignore migration from js(x) to ts(x)
          new Immutable.Set(),
          item_ids => item_ids.add(integration.get('id'))
        )
        .mergeDeepIn(['meta'], {
          loading: false,
          updated_at: Date.now(),
          success: true
        })
    }
    default:
      return state
  }
}

export default filterActions(integrations, [
  UPDATE_INTEGRATION,
  CREATE_INTEGRATION,
  FETCH_INTEGRATIONS,
  UPDATE_INTEGRATION_FAILED,
  CREATE_INTEGRATION_FAILED,
  FETCH_INTEGRATIONS_FAILED,
  FETCH_INTEGRATIONS_SUCCEEDED,
  CREATE_INTEGRATION_SUCCEEDED,
  UPDATE_INTEGRATION_SUCCEEDED
])
