import type { AttachmentResponseSchema } from '@libs/data-access/queries'
import {
  appQueryClient,
  type ApiErrorResponse,
  type UseAppMutationOptions
} from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import type { PostLegacyMembershipDocumentsOptions } from '../membership.documents.api'
import { postLegacyMembershipDocuments } from '../membership.documents.api'
import { membershipDocumentsCacheKeys } from '../membership.documents.cacheKeys'

export const useLegacyPostMembershipDocuments = (
  mutationOptions?: UseAppMutationOptions<
    AttachmentResponseSchema,
    ApiErrorResponse,
    PostLegacyMembershipDocumentsOptions
  >
) => {
  return useMutation<
    AttachmentResponseSchema,
    ApiErrorResponse,
    PostLegacyMembershipDocumentsOptions
  >({
    ...mutationOptions,
    mutationKey:
      membershipDocumentsCacheKeys.post_legacy_membership_documents(),
    mutationFn: opts => postLegacyMembershipDocuments(opts),
    onSuccess: (...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(...args)
      }
      appQueryClient.invalidateQueries({
        queryKey: membershipDocumentsCacheKeys.fetch_membership_documents()
      })
    }
  })
}
