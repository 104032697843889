import type { UseAppQueryOptions } from '@libs/data-access/queries'
import { type PaginatedQueryResponse } from '@libs/data-access/queries'
import { useQuery } from '@tanstack/react-query'

import {
  fetchMembershipDocuments,
  type FetchMembershipDocumentsOptions
} from '../membership.documents.api'
import { membershipDocumentsCacheKeys } from '../membership.documents.cacheKeys'
import type { MembershipDocumentSchema } from '../types/membership.documents.schema'

export const useFetchMembershipDocuments = (
  request: FetchMembershipDocumentsOptions,
  queryOptions?: UseAppQueryOptions<
    PaginatedQueryResponse<MembershipDocumentSchema>
  >
) => {
  return useQuery({
    ...queryOptions,
    queryKey: membershipDocumentsCacheKeys.fetch_membership_documents(
      request.queryParams
    ),
    queryFn: () => fetchMembershipDocuments(request)
  })
}
