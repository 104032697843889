import type { CountryCode } from 'libphonenumber-js/max'
import {
  parsePhoneNumberFromString,
  parsePhoneNumberWithError
} from 'libphonenumber-js/max'

import type { PhoneInputValue } from '../base/PhoneInput.types'

const FIXED_ARR = ['FIXED_LINE', 'VOIP']
const MOBILE_ARR = ['MOBILE', 'FIXED_LINE_OR_MOBILE']

export const phoneInputUtils = {
  /**
   * Check if the phone number is valid
   */
  isValid: (value: PhoneInputValue) => {
    const parsed = parsePhoneNumberFromString(value.number, value.countryCode)
    return parsed?.isValid() || false
  },

  /**
   * Transform the phone number to international format
   */
  transformToInternationalFormat: (value: PhoneInputValue) => {
    const parsed = parsePhoneNumberFromString(value.number, value.countryCode)
    return parsed?.formatInternational()
  },

  /**
   * Transform the phone number to international format
   */
  transformToPhoneInputValue: (
    value: string,
    defaultCountry: CountryCode = '' as CountryCode
  ): PhoneInputValue => {
    try {
      const parsed = parsePhoneNumberFromString(value)
      return {
        number: parsed?.nationalNumber || '',
        countryCode: parsed?.country || defaultCountry
      }
    } catch (error) {
      return {
        number: '',
        countryCode: defaultCountry
      }
    }
  },

  /**
   * Check if the phone number is a valid mobile number
   */
  isValidMobile: (value: PhoneInputValue) => {
    try {
      const parsed = parsePhoneNumberWithError(value.number, value.countryCode)
      if (!parsed) {
        return false
      }
      return MOBILE_ARR.includes(parsed?.getType() || '') && parsed?.isValid()
    } catch (error) {
      return false
    }
  },

  /**
   * Check if the phone number is a valid fixed-line number
   */
  isValidFixedLine: (value: PhoneInputValue) => {
    try {
      const parsed = parsePhoneNumberWithError(value.number, value.countryCode)
      if (!parsed) {
        return false
      }
      return FIXED_ARR.includes(parsed?.getType() || '') && parsed?.isValid()
    } catch (error) {
      return false
    }
  }
}
