import {
  appQueryClient,
  type ApiErrorResponse,
  type UseAppMutationOptions
} from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import type { DeleteMembershipDocumentsOptions } from '../membership.documents.api'
import { deleteMembershipDocuments } from '../membership.documents.api'
import { membershipDocumentsCacheKeys } from '../membership.documents.cacheKeys'

export const useDeleteMembershipDocuments = (
  mutationOptions?: UseAppMutationOptions<
    {},
    ApiErrorResponse,
    DeleteMembershipDocumentsOptions
  >
) => {
  return useMutation<{}, ApiErrorResponse, DeleteMembershipDocumentsOptions>({
    ...mutationOptions,
    mutationKey: membershipDocumentsCacheKeys.delete_membership_documents(),
    mutationFn: opts => deleteMembershipDocuments(opts),
    onSuccess: (...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(...args)
      }
      appQueryClient.invalidateQueries({
        queryKey: membershipDocumentsCacheKeys.fetch_membership_documents()
      })
    }
  })
}
