import account_locations from './modules/account_locations'
import accounts from './modules/accounts'
import allLocationsReportFiles from './modules/allLocationsReportFiles'
import amendments from './modules/amendments'
import articles from './modules/articles'
import collectiveAgreements from './modules/collectiveAgreements'
import contractEvents from './modules/contractEvents'
import contractTemplates from './modules/contractTemplates'
import contracts from './modules/contracts'
import conversationAttachments from './modules/conversationAttachments'
import conversations from './modules/conversations'
import counters from './modules/counters'
import countryConfigurations from './modules/countryConfigurations/reducer'
import countryMinWages from './modules/country_min_wages'
import dashboardKPIs from './modules/dashboard_kpis'
import dsnEvents from './modules/dsnEvents'
import dsnFiles from './modules/dsnFiles'
import employeeIdSequence from './modules/employee_id_sequence'
import exportFiles from './modules/exportFiles'
import holidays from './modules/holidays'
import integrations from './modules/integrations'
import labels from './modules/labels'
import locations from './modules/locations'
import memberships from './modules/memberships'
import messages from './modules/messages'
import modulationPeriodCounterEntries from './modules/modulationPeriodCounterEntries'
import modulationPeriodCounters from './modules/modulationPeriodCounters'
import modulationPeriods from './modules/modulationPeriods'
import notes from './modules/notes'
import notifications from './modules/notifications'
import padUsers from './modules/padUsers'
import paidLeaveConfiguration from './modules/paidLeaveLedgerOperations/reducer'
import payPeriods from './modules/payPeriods'
import payrollSoftware from './modules/payrollSoftware'
import payrollSoftwareConfigurations from './modules/payrollSoftwareConfigurations'
import payrolls from './modules/payrolls'
import reportFiles from './modules/reportFiles'
import reports from './modules/reports'
import rests from './modules/rests'
import revenues from './modules/revenues'
import rewardTemplates from './modules/rewardTemplates'
import rewards from './modules/rewards'
import shiftTemplates from './modules/shiftTemplates'
import shifts from './modules/shifts'
import socket from './modules/socket'
import teams from './modules/teams'
import timeoffs from './modules/timeoffs'
import timesheets from './modules/timesheets/reducers'
import users from './modules/users'
import weeklyschedules from './modules/weeklyschedules'

// Exporting the reducers that aren't manage by ImmutableJS
// You need to move your state from the default export to the constant below.
export const rootReducer = {
  articles,
  countryConfigurations,
  paidLeaveConfiguration
}

// Please keep this in alphabetical order.
export default {
  account_locations,
  accounts,
  allLocationsReportFiles,
  amendments,
  collectiveAgreements,
  contractEvents,
  contracts,
  contractTemplates,
  conversationAttachments,
  conversations,
  counters,
  countryMinWages,
  dashboardKPIs,
  dsnEvents,
  dsnFiles,
  employeeIdSequence,
  exportFiles,
  holidays,
  integrations,
  labels,
  locations,
  memberships,
  messages,
  modulationPeriodCounterEntries,
  modulationPeriodCounters,
  modulationPeriods,
  notes,
  notifications,
  padUsers,
  payPeriods,
  payrolls,
  payrollSoftware,
  payrollSoftwareConfigurations,
  reportFiles,
  reports,
  rests,
  revenues,
  rewards,
  rewardTemplates,
  shifts,
  shiftTemplates,
  socket,
  teams,
  timeoffs,
  timesheets,
  users,
  weeklyschedules
}
