import billingSagas from '@spa/redux/billing/sagas'
import restModal from '@spa/scenes/Plannings/Modals/RestModal/redux/sagas'
import plannings from '@spa/scenes/Plannings/redux/sagas'
import { all } from 'redux-saga/effects'
import Sagas from 'snap-redux/sagas'

import documentTemplate from './emp/DocumentTemplate/sagas'
import missingInformation from './emp/MissingInformation/sagas'
import paidLeavesLedger from './emp/PaidLeavesLedger/sagas'
import planningWeeklySagas from './planning/planningWeekly/sagas'

export default function* saga() {
  yield all([
    ...Sagas,

    ...planningWeeklySagas,
    ...restModal,
    ...plannings,
    ...missingInformation,
    ...paidLeavesLedger,
    ...documentTemplate,
    ...billingSagas
  ])
}
