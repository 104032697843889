import {
  appQueryClient,
  type ApiErrorResponse,
  type UseAppMutationOptions
} from '@libs/data-access/queries'
import { useMutation } from '@tanstack/react-query'

import { electronicSignatureDocumentsQueryKeys } from '../../../../ElectronicSignature/Documents/electronicSignature.documents.cacheKeys'
import type { PostMembershipDocumentSignatureOptions } from '../membership.documents.api'
import { postMembershipDocumentSignature } from '../membership.documents.api'
import { membershipDocumentsCacheKeys } from '../membership.documents.cacheKeys'

export const usePostMembershipDocumentSignature = (
  mutationOptions?: UseAppMutationOptions<
    {},
    ApiErrorResponse,
    PostMembershipDocumentSignatureOptions
  >
) => {
  return useMutation<
    {},
    ApiErrorResponse,
    PostMembershipDocumentSignatureOptions
  >({
    ...mutationOptions,
    mutationKey:
      membershipDocumentsCacheKeys.post_membership_documents_signature(),
    mutationFn: opts => postMembershipDocumentSignature(opts),
    onSuccess: (...args) => {
      if (mutationOptions?.onSuccess) {
        mutationOptions?.onSuccess(...args)
      }
      appQueryClient.invalidateQueries({
        queryKey: membershipDocumentsCacheKeys.fetch_membership_documents()
      })
      appQueryClient.invalidateQueries({
        queryKey:
          electronicSignatureDocumentsQueryKeys.fetch_paginated_document()
      })
    }
  })
}
