import type {
  InitalFormatType,
  PaginatedQueryRequest,
  PaginatedQueryResponse,
  SignatoriesFormatType
} from '@libs/data-access/queries'
import { type QueryFnParams, httpServices } from '@libs/data-access/queries'

import type { MembershipDocumentSchema } from './types/membership.documents.schema'

export type FetchMembershipDocumentsOptions = QueryFnParams<{
  queryParams: Partial<PaginatedQueryRequest> & {
    membership_id: number
  }
}>

export const fetchMembershipDocuments = async (
  opts?: FetchMembershipDocumentsOptions
) => {
  const response = await httpServices.core.get<
    PaginatedQueryResponse<MembershipDocumentSchema>
  >(`/employee_management/documents`, {
    params: opts?.queryParams
  })
  return response.data
}

export type DeleteMembershipDocumentsOptions = QueryFnParams<{
  pathParams: {
    document_id: number
  }
}>

export const deleteMembershipDocuments = async (
  opts: DeleteMembershipDocumentsOptions
) => {
  const response = await httpServices.core.delete(
    `/employee_management/documents/${opts.pathParams.document_id}`
  )
  return response.data
}

export type PostLegacyMembershipDocumentsOptions = QueryFnParams<{
  payload: {
    attachment_data: {
      filename: string
      content_type: string
      data: string | ArrayBuffer | null
    }
    membership_id: string
    title: string
  }
}>

export const postLegacyMembershipDocuments = async (
  opts: PostLegacyMembershipDocumentsOptions
) => {
  const response = await httpServices.core.post(`/attachments`, {
    ...opts.payload
  })
  return response.data
}

export type PostMembershipDocumentSignatureOptions = QueryFnParams<{
  pathParams: {
    document_id: number
  }
  payload: {
    document: {
      data: string
      filename: string
      originalFile?: File
    }
    manually_positioned: boolean
    signatories: SignatoriesFormatType[]
    ordered_signatures?: boolean
    reminder_delay?: number
    read_and_approved?: boolean
    initals?: InitalFormatType
  }
}>

export const postMembershipDocumentSignature = async (
  opts: PostMembershipDocumentSignatureOptions
) => {
  const response = await httpServices.core.post(
    `/employee_management/documents/${opts.pathParams.document_id}/request_signature`,
    opts.payload
  )
  return response.data
}

export type ConvertDocumentOptions = QueryFnParams<{
  pathParams: {
    document_id: number
  }
}>

/**
 *
 */
export const convertDocument = async (opts: ConvertDocumentOptions) => {
  const response = await httpServices.core.post(
    `/employee_management/documents/${opts.pathParams.document_id}/convert`
  )
  return response.data
}
