// ------------------------------------
// Accounts
// ------------------------------------
export const FETCH_ACCOUNT = 'api/FETCH_ACCOUNT'
export const FETCH_ACCOUNT_SUCCEEDED = 'api/FETCH_ACCOUNT_SUCCEEDED'
export const FETCH_ACCOUNT_FAILED = 'api/FETCH_ACCOUNT_FAILED'

export const UPDATE_ACCOUNT = 'api/UPDATE_ACCOUNT'
export const UPDATE_ACCOUNT_SUCCEEDED = 'api/UPDATE_ACCOUNT_SUCCEEDED'
export const UPDATE_ACCOUNT_FAILED = 'api/UPDATE_ACCOUNT_FAILED'

export const UPDATE_ACCOUNT_REORDER = 'api/UPDATE_ACCOUNT_REORDER'
export const UPDATE_ACCOUNT_REORDER_SUCCEEDED =
  'api/UPDATE_ACCOUNT_REORDER_SUCCEEDED'
export const UPDATE_ACCOUNT_REORDER_FAILED = 'api/UPDATE_ACCOUNT_REORDER_FAILED'

// ------------------------------------
// Articles
// ------------------------------------
export const FETCH_ARTICLES = 'api/FETCH_ARTICLES'
export const FETCH_ARTICLES_SUCCEEDED = 'api/FETCH_ARTICLES_SUCCEEDED'
export const FETCH_ARTICLES_FAILED = 'api/FETCH_ARTICLES_FAILED'

export const FETCH_ARTICLE = 'api/FETCH_ARTICLE'
export const FETCH_ARTICLE_SUCCEEDED = 'api/FETCH_ARTICLE_SUCCEEDED'
export const FETCH_ARTICLE_FAILED = 'api/FETCH_ARTICLE_FAILED'

export const CREATE_ARTICLE = 'api/CREATE_ARTICLES '
export const CREATE_ARTICLE_SUCCEEDED = 'api/CREATE_ARTICLES_SUCCEEDED'
export const CREATE_ARTICLE_FAILED = 'api/CREATE_ARTICLES_FAILED'

export const UPDATE_ARTICLE = 'api/UPDATE_ARTICLE'
export const UPDATE_ARTICLE_SUCCEEDED = 'api/UPDATE_ARTICLE_SUCCEEDED'
export const UPDATE_ARTICLE_FAILED = 'api/UPDATE_ARTICLE_FAILED'

export const DELETE_ARTICLE = 'api/DELETE_ARTICLE'
export const DELETE_ARTICLE_SUCCEEDED = 'api/DELETE_ARTICLE_SUCCEEDED'
export const DELETE_ARTICLE_FAILED = 'api/DELETE_ARTICLE_FAILED'

// ------------------------------------
// Collective agreements
// ------------------------------------
export const FETCH_COLLECTIVE_AGREEMENT = 'api/FETCH_COLLECTIVE_AGREEMENT'
export const FETCH_COLLECTIVE_AGREEMENT_SUCCEEDED =
  'api/FETCH_COLLECTIVE_AGREEMENT_SUCCEEDED'
export const FETCH_COLLECTIVE_AGREEMENT_FAILED =
  'api/FETCH_COLLECTIVE_AGREEMENT_FAILED'

export const FETCH_COLLECTIVE_AGREEMENTS = 'api/FETCH_COLLECTIVE_AGREEMENTS'
export const FETCH_COLLECTIVE_AGREEMENTS_SUCCEEDED =
  'api/FETCH_COLLECTIVE_AGREEMENTS_SUCCEEDED'
export const FETCH_COLLECTIVE_AGREEMENTS_FAILED =
  'api/FETCH_COLLECTIVE_AGREEMENTS_FAILED'

// ------------------------------------
// Contracts
// ------------------------------------
export const FETCH_CONTRACTS = 'api/FETCH_CONTRACTS'
export const FETCH_CONTRACTS_SUCCEEDED = 'api/FETCH_CONTRACTS_SUCCEEDED'
export const FETCH_CONTRACTS_FAILED = 'api/FETCH_CONTRACTS_FAILED'

export const FETCH_PAGINATED_CONTRACTS = 'api/FETCH_PAGINATED_CONTRACTS'
export const FETCH_PAGINATED_FILTERED_CONTRACTS =
  'api/FETCH_PAGINATED_FILTERED_CONTRACTS'
export const FETCH_PAGINATED_CONTRACTS_SUCCEEDED =
  'api/FETCH_PAGINATED_CONTRACTS_SUCCEEDED'
export const FETCH_PAGINATED_CONTRACTS_FAILED =
  'api/FETCH_PAGINATED_CONTRACTS_FAILED'

export const FETCH_CONTRACT = 'api/FETCH_CONTRACT'
export const FETCH_CONTRACT_SUCCEEDED = 'api/FETCH_CONTRACT_SUCCEEDED'
export const FETCH_CONTRACT_FAILED = 'api/FETCH_CONTRACT_FAILED'

export const CREATE_CONTRACT = 'api/CREATE_CONTRACT'
export const CREATE_CONTRACT_SUCCEEDED = 'api/CREATE_CONTRACT_SUCCEEDED'
export const CREATE_CONTRACT_FAILED = 'api/CREATE_CONTRACT_FAILED'

export const UPDATE_CONTRACT = 'api/UPDATE_CONTRACT'
export const UPDATE_CONTRACT_SUCCEEDED = 'api/UPDATE_CONTRACT_SUCCEEDED'
export const UPDATE_CONTRACT_FAILED = 'api/UPDATE_CONTRACT_FAILED'

export const DELETE_CONTRACT = 'api/DELETE_CONTRACT'
export const DELETE_CONTRACT_SUCCEEDED = 'api/DELETE_CONTRACT_SUCCEEDED'
export const DELETE_CONTRACT_FAILED = 'api/DELETE_CONTRACT_FAILED'

// ------------------------------------
// Contract Events
// ------------------------------------
export const FETCH_PAGINATED_CONTRACT_EVENTS =
  'api/FETCH_PAGINATED_CONTRACT_EVENTS'
export const FETCH_PAGINATED_CONTRACT_EVENTS_SUCCEEDED =
  'api/FETCH_PAGINATED_CONTRACT_EVENTS_SUCCEEDED'
export const FETCH_PAGINATED_CONTRACT_EVENTS_FAILED =
  'api/FETCH_PAGINATED_CONTRACT_EVENTS_FAILED'

// ------------------------------------
// Contract templates
// ------------------------------------
export const FETCH_CONTRACT_TEMPLATES = 'api/FETCH_CONTRACT_TEMPLATES'
export const FETCH_CONTRACT_TEMPLATES_SUCCEEDED =
  'api/FETCH_CONTRACT_TEMPLATES_SUCCEEDED'
export const FETCH_CONTRACT_TEMPLATES_FAILED =
  'api/FETCH_CONTRACT_TEMPLATES_FAILED'

export const CREATE_CONTRACT_TEMPLATE = 'api/CREATE_CONTRACT_TEMPLATE'
export const CREATE_CONTRACT_TEMPLATE_SUCCEEDED =
  'api/CREATE_CONTRACT_TEMPLATE_SUCCEEDED'
export const CREATE_CONTRACT_TEMPLATE_FAILED =
  'api/CREATE_CONTRACT_TEMPLATE_FAILED'

export const UPDATE_CONTRACT_TEMPLATE = 'api/UPDATE_CONTRACT_TEMPLATE'
export const UPDATE_CONTRACT_TEMPLATE_SUCCEEDED =
  'api/UPDATE_CONTRACT_TEMPLATE_SUCCEEDED'
export const UPDATE_CONTRACT_TEMPLATE_FAILED =
  'api/UPDATE_CONTRACT_TEMPLATE_FAILED'

export const DELETE_CONTRACT_TEMPLATE = 'api/DELETE_CONTRACT_TEMPLATE'
export const DELETE_CONTRACT_TEMPLATE_SUCCEEDED =
  'api/DELETE_CONTRACT_TEMPLATE_SUCCEEDED'
export const DELETE_CONTRACT_TEMPLATE_FAILED =
  'api/DELETE_CONTRACT_TEMPLATE_FAILED'

export const GENERATE_CONTRACT = 'api/GENERATE_CONTRACT'
export const GENERATE_CONTRACT_SUCCEEDED = 'api/GENERATE_CONTRACT_SUCCEEDED'
export const GENERATE_CONTRACT_FAILED = 'api/GENERATE_CONTRACT_FAILED'

// ------------------------------------
// Counters
// ------------------------------------
export const FETCH_COUNTERS_BY_CONTRACT_ID = 'api/FETCH_COUNTERS_BY_CONTRACT_ID'
export const FETCH_COUNTERS_BY_CONTRACT_ID_SUCCEEDED =
  'api/FETCH_COUNTERS_BY_CONTRACT_ID_SUCCEEDED'
export const FETCH_COUNTERS_BY_CONTRACT_ID_FAILED =
  'api/FETCH_COUNTERS_BY_CONTRACT_ID_FAILED'

export const FETCH_COUNTERS_BY_LOCATION_ID = 'api/FETCH_COUNTERS_BY_LOCATION_ID'
export const FETCH_COUNTERS_BY_LOCATION_ID_SUCCEEDED =
  'api/FETCH_COUNTERS_BY_LOCATION_ID_SUCCEEDED'
export const FETCH_COUNTERS_BY_LOCATION_ID_FAILED =
  'api/FETCH_COUNTERS_BY_LOCATION_ID_FAILED'

// ------------------------------------
// Counter history
// ------------------------------------
export const FETCH_COUNTER_HISTORY = 'api/FETCH_COUNTER_HISTORY'
export const FETCH_COUNTER_HISTORY_SUCCEEDED =
  'api/FETCH_COUNTER_HISTORY_SUCCEEDED'
export const FETCH_COUNTER_HISTORY_FAILED = 'api/FETCH_COUNTER_HISTORY_FAILED'

// ------------------------------------
// Conversations
// ------------------------------------
export const FETCH_CONVERSATION = 'api/FETCH_CONVERSATION'
export const FETCH_CONVERSATION_SUCCEEDED = 'api/FETCH_CONVERSATION_SUCCEEDED'
export const FETCH_CONVERSATION_FAILED = 'api/FETCH_CONVERSATION_FAILED'

export const FETCH_CONVERSATIONS = 'api/FETCH_CONVERSATIONS'
export const FETCH_CONVERSATIONS_SUCCEEDED = 'api/FETCH_CONVERSATIONS_SUCCEEDED'
export const FETCH_CONVERSATIONS_FAILED = 'api/FETCH_CONVERSATIONS_FAILED'

export const REFRESH_CONVERSATIONS = 'api/REFRESH_CONVERSATIONS'
export const REFRESH_CONVERSATIONS_SUCCEEDED =
  'api/REFRESH_CONVERSATIONS_SUCCEEDED'
export const REFRESH_CONVERSATIONS_FAILED = 'api/REFRESH_CONVERSATIONS_FAILED'

export const FETCH_CONVERSATION_ATTACHMENTS =
  'api/FETCH_CONVERSATION_ATTACHMENTS'
export const FETCH_CONVERSATION_ATTACHMENTS_SUCCEEDED =
  'api/FETCH_CONVERSATION_ATTACHMENTS_SUCCEEDED'
export const FETCH_CONVERSATION_ATTACHMENTS_FAILED =
  'api/FETCH_CONVERSATION_ATTACHMENTS_FAILED'

export const CREATE_CONVERSATION = 'api/CREATE_CONVERSATION'
export const CREATE_CONVERSATION_SUCCEEDED = 'api/CREATE_CONVERSATION_SUCCEEDED'
export const CREATE_CONVERSATION_FAILED = 'api/CREATE_CONVERSATION_FAILED'

export const UPDATE_CONVERSATION = 'api/UPDATE_CONVERSATION'
export const UPDATE_CONVERSATION_SUCCEEDED = 'api/UPDATE_CONVERSATION_SUCCEEDED'
export const UPDATE_CONVERSATION_FAILED = 'api/UPDATE_CONVERSATION_FAILED'

// ------------------------------------
// Dashboard KPIs
// ------------------------------------

export const FETCH_DASHBOARD_KPIS = 'api/FETCH_DASHBOARD_KPIS'
export const FETCH_DASHBOARD_KPIS_SUCCEEDED =
  'api/FETCH_DASHBOARD_KPIS_SUCCEEDED'
export const FETCH_DASHBOARD_KPIS_FAILED = 'api/FETCH_DASHBOARD_KPIS_FAILED'

export const FETCH_DASHBOARD_KPIS_TURNOVERS =
  'api/FETCH_DASHBOARD_KPIS_TURNOVERS'
export const FETCH_DASHBOARD_KPIS_TURNOVERS_SUCCEEDED =
  'api/FETCH_DASHBOARD_KPIS_TURNOVERS_SUCCEEDED'
export const FETCH_DASHBOARD_KPIS_TURNOVERS_FAILED =
  'api/FETCH_DASHBOARD_KPIS_TURNOVERS_FAILED'

export const FETCH_MONTHLY_DASHBOARD_TURNOVERS =
  'api/FETCH_MONTHLY_DASHBOARD_TURNOVERS'
export const FETCH_MONTHLY_DASHBOARD_TURNOVERS_SUCCEEDED =
  'api/FETCH_MONTHLY_DASHBOARD_TURNOVERS_SUCCEEDED'
export const FETCH_MONTHLY_DASHBOARD_TURNOVERS_FAILED =
  'api/FETCH_MONTHLY_DASHBOARD_TURNOVERS_FAILED'

// ------------------------------------
// dsnEvents
// ------------------------------------
export const FETCH_PAGINATED_DSN_EVENTS = 'api/FETCH_PAGINATED_DSN_EVENTS'
export const FETCH_PAGINATED_DSN_EVENTS_SUCCEEDED =
  'api/FETCH_PAGINATED_DSN_EVENTS_SUCCEEDED'
export const FETCH_PAGINATED_DSN_EVENTS_FAILED =
  'api/FETCH_PAGINATED_DSN_EVENTS_FAILED'
// ------------------------------------
// DSN Files
// ------------------------------------
export const FETCH_DSN_FILES = 'api/FETCH_DSN_FILES'
export const FETCH_DSN_FILES_SUCCEEDED = 'api/FETCH_DSN_FILES_SUCCEEDED'
export const FETCH_DSN_FILES_FAILED = 'api/FETCH_DSN_FILES_FAILED'
export const CREATE_DSN_FILES = 'api/CREATE_DSN_FILES'
export const CREATE_DSN_FILES_SUCCEEDED = 'api/CREATE_DSN_FILES_SUCCEEDED'
export const CREATE_DSN_FILES_FAILED = 'api/CREATE_DSN_FILES_FAILED'

// ------------------------------------
// Export Files
// ------------------------------------
export const CREATE_EXPORT_FILES = 'api/CREATE_EXPORT_FILES'
export const CREATE_EXPORT_FILES_SUCCEEDED = 'api/CREATE_EXPORT_FILES_SUCCEEDED'
export const CREATE_EXPORT_FILES_FAILED = 'api/CREATE_EXPORT_FILES_FAILED'

export const GENERATE_EXPORT_FILE_SUCCEEDED =
  'api/GENERATE_EXPORT_FILE_SUCCEEDED'

// ------------------------------------
// Special Export
// ------------------------------------

export const CREATE_SPECIAL_EXPORT_FILE = 'api/CREATE_SPECIAL_EXPORT_FILE'
export const CREATE_SPECIAL_EXPORT_FILE_SUCCEEDED =
  'api/CREATE_SPECIAL_EXPORT_FILE_SUCCEEDED'
export const CREATE_SPECIAL_EXPORT_FILE_FAILED = 'api/CREATE_EXPORT_FILE_FAILED'

// ------------------------------------
// Holidays
// ------------------------------------
export const FETCH_HOLIDAYS = 'api/FETCH_HOLIDAYS'
export const FETCH_HOLIDAYS_SUCCEEDED = 'api/FETCH_HOLIDAYS_SUCCEEDED'
export const FETCH_HOLIDAYS_FAILED = 'api/FETCH_HOLIDAYS_FAILED'

// ------------------------------------
// Integrations
// ------------------------------------
export const FETCH_INTEGRATIONS = 'api/FETCH_INTEGRATIONS'
export const FETCH_INTEGRATIONS_SUCCEEDED = 'api/FETCH_INTEGRATIONS_SUCCEEDED'
export const FETCH_INTEGRATIONS_FAILED = 'api/FETCH_INTEGRATIONS_FAILED'

export const UPDATE_INTEGRATION = 'api/UPDATE_INTEGRATION'
export const UPDATE_INTEGRATION_SUCCEEDED = 'api/UPDATE_INTEGRATION_SUCCEEDED'
export const UPDATE_INTEGRATION_FAILED = 'api/UPDATE_INTEGRATION_FAILED'

export const CREATE_INTEGRATION = 'api/CREATE_INTEGRATION'
export const CREATE_INTEGRATION_SUCCEEDED = 'api/CREATE_INTEGRATION_SUCCEEDED'
export const CREATE_INTEGRATION_FAILED = 'api/CREATE_INTEGRATION_FAILED'

// ------------------------------------
// Labels
// ------------------------------------
export const FETCH_LABELS = 'api/FETCH_LABELS'
export const FETCH_LABELS_FROM_LOCATION = 'api/FETCH_LABELS_FROM_LOCATION'
export const FETCH_LABELS_FROM_ACCOUNT = 'api/FETCH_LABELS_FROM_TEAM'
export const FETCH_LABELS_SUCCEEDED = 'api/FETCH_LABELS_SUCCEEDED'
export const FETCH_LABELS_FAILED = 'api/FETCH_LABELS_FAILED'

export const CREATE_LABEL = 'api/CREATE_LABEL'
export const CREATE_LABEL_SUCCEEDED = 'api/CREATE_LABEL_SUCCEEDED'
export const CREATE_LABEL_FAILED = 'api/CREATE_LABEL_FAILED'

export const UPDATE_LABEL = 'api/UPDATE_LABEL'
export const UPDATE_LABEL_SUCCEEDED = 'api/UPDATE_LABEL_SUCCEEDED'
export const UPDATE_LABEL_FAILED = 'api/UPDATE_LABEL_FAILED'

export const DELETE_LABEL = 'api/DELETE_LABEL'
export const DELETE_LABEL_SUCCEEDED = 'api/DELETE_LABEL_SUCCEEDED'
export const DELETE_LABEL_FAILED = 'api/DELETE_LABEL_FAILED'

// ------------------------------------
// Locations
// ------------------------------------
export const FETCH_LOCATIONS = 'api/FETCH_LOCATIONS'
export const FETCH_LOCATIONS_THROTTLED = 'api/FETCH_LOCATIONS_THROTTLED'
export const FETCH_LOCATIONS_SUCCEEDED = 'api/FETCH_LOCATIONS_SUCCEEDED'
export const FETCH_LOCATIONS_FAILED = 'api/FETCH_LOCATIONS_FAILED'

export const FETCH_LOCATION = 'api/FETCH_LOCATION'
export const FETCH_LOCATION_SUCCEEDED = 'api/FETCH_LOCATION_SUCCEEDED'
export const FETCH_LOCATION_FAILED = 'api/FETCH_LOCATION_FAILED'

export const CREATE_LOCATION = 'api/CREATE_LOCATION'
export const CREATE_LOCATION_SUCCEEDED = 'api/CREATE_LOCATION_SUCCEEDED'
export const CREATE_LOCATION_FAILED = 'api/CREATE_LOCATION_FAILED'

export const UPDATE_LOCATION = 'api/UPDATE_LOCATION'
export const UPDATE_LOCATION_SUCCEEDED = 'api/UPDATE_LOCATION_SUCCEEDED'
export const UPDATE_LOCATION_FAILED = 'api/UPDATE_LOCATION_FAILED'

// ------------------------------------
// Account Locations
// ------------------------------------
export const FETCH_ACCOUNT_LOCATIONS = 'api/FETCH_ACCOUNT_LOCATIONS'
export const FETCH_ACCOUNT_LOCATIONS_SUCCEEDED =
  'api/FETCH_ACCOUNT_LOCATIONS_SUCCEEDED'
export const FETCH_ACCOUNT_LOCATIONS_FAILED =
  'api/FETCH_ACCOUNT_LOCATIONS_FAILED'

// ------------------------------------
// Memberships
// ------------------------------------
export const FETCH_MEMBERSHIPS = 'api/FETCH_MEMBERSHIPS'
export const FETCH_MEMBERSHIPS_SUCCEEDED = 'api/FETCH_MEMBERSHIPS_SUCCEEDED'
export const FETCH_MEMBERSHIPS_FAILED = 'api/FETCH_MEMBERSHIPS_FAILED'

export const FETCH_MEMBERLIST_MEMBERSHIPS = 'api/FETCH_MEMBERLIST_MEMBERSHIPS'
export const FETCH_MEMBERLIST_MEMBERSHIPS_SUCCEEDED =
  'api/FETCH_MEMBERLIST_MEMBERSHIPS_SUCCEEDED'
export const FETCH_MEMBERLIST_MEMBERSHIPS_FAILED =
  'api/FETCH_MEMBERLIST_MEMBERSHIPS_FAILED'

export const FETCH_NOTIFICATION_MEMBERSHIPS =
  'api/FETCH_NOTIFICATION_MEMBERSHIPS'
export const FETCH_NOTIFICATION_MEMBERSHIPS_SUCCEEDED =
  'api/FETCH_NOTIFICATION_MEMBERSHIPS_SUCCEEDED'
export const FETCH_NOTIFICATION_MEMBERSHIPS_FAILED =
  'api/FETCH_MEMBERLIST_MEMBERSHIPS_FAILED'

export const FETCH_MEMBERSHIP = 'api/FETCH_MEMBERSHIP'
export const FETCH_MEMBERSHIP_SUCCEEDED = 'api/FETCH_MEMBERSHIP_SUCCEEDED'
export const FETCH_MEMBERSHIP_FAILED = 'api/FETCH_MEMBERSHIP_FAILED'

export const CREATE_MEMBERSHIP = 'api/CREATE_MEMBERSHIP'
export const CREATE_MEMBERSHIP_SUCCEEDED = 'api/CREATE_MEMBERSHIP_SUCCEEDED'
export const CREATE_MEMBERSHIP_FAILED = 'api/CREATE_MEMBERSHIP_FAILED'

export const UPDATE_MEMBERSHIP = 'api/UPDATE_MEMBERSHIP'
export const UPDATE_MEMBERSHIP_SUCCEEDED = 'api/UPDATE_MEMBERSHIP_SUCCEEDED'
export const UPDATE_MEMBERSHIP_FAILED = 'api/UPDATE_MEMBERSHIP_FAILED'

export const UPDATE_MEMBERSHIP_PERMISSIONS = 'api/UPDATE_MEMBERSHIP_PERMISSIONS'
export const UPDATE_MEMBERSHIP_PERMISSIONS_SUCCEEDED =
  'api/UPDATE_MEMBERSHIP_PERMISSIONS_SUCCEEDED'
export const UPDATE_MEMBERSHIP_PERMISSIONS_FAILED =
  'api/UPDATE_MEMBERSHIP_PERMISSIONS_FAILED'

export const DELETE_MEMBERSHIP = 'api/DELETE_MEMBERSHIP'
export const DELETE_MEMBERSHIP_SUCCEEDED = 'api/DELETE_MEMBERSHIP_SUCCEEDED'
export const DELETE_MEMBERSHIP_FAILED = 'api/DELETE_MEMBERSHIP_FAILED'

export const INVITE_MEMBERSHIP = 'api/INVITE_MEMBERSHIP'
export const INVITE_MEMBERSHIP_SUCCEEDED = 'api/INVITE_MEMBERSHIP_SUCCEEDED'
export const INVITE_MEMBERSHIP_FAILED = 'api/INVITE_MEMBERSHIP_FAILED'

export const FETCH_MEMBERSHIPS_TUTORS = 'api/FETCH_MEMBERSHIPS_TUTORS'
export const FETCH_MEMBERSHIPS_TUTORS_SUCCEEDED =
  'api/FETCH_MEMBERSHIPS_TUTORS_SUCCEEDED'
export const FETCH_MEMBERSHIPS_TUTORS_FAILED =
  'api/FETCH_MEMBERSHIPS_TUTORS_FAILED'

// ------------------------------------
// Messages
// ------------------------------------
export const FETCH_MESSAGES = 'api/FETCH_MESSAGES'
export const FETCH_MESSAGES_SUCCEEDED = 'api/FETCH_MESSAGES_SUCCEEDED'
export const FETCH_MESSAGES_FAILED = 'api/FETCH_MESSAGES_FAILED'

export const REFRESH_MESSAGES = 'api/REFRESH_MESSAGES'
export const REFRESH_MESSAGES_SUCCEEDED = 'api/REFRESH_MESSAGES_SUCCEEDED'
export const REFRESH_MESSAGES_FAILED = 'api/REFRESH_MESSAGES_FAILED'

export const CREATE_MESSAGE = 'api/CREATE_MESSAGE'
export const CREATE_MESSAGE_SUCCEEDED = 'api/CREATE_MESSAGE_SUCCEEDED'
export const CREATE_MESSAGE_FAILED = 'api/CREATE_MESSAGE_FAILED'

export const UPDATE_MESSAGE = 'api/UPDATE_MESSAGE'
export const UPDATE_MESSAGE_SUCCEEDED = 'api/UPDATE_MESSAGE_SUCCEEDED'
export const UPDATE_MESSAGE_FAILED = 'api/UPDATE_MESSAGE_FAILED'

// ------------------------------------
// Modulation periods
// ------------------------------------
export const FETCH_MODULATION_PERIOD = 'api/FETCH_MODULATION_PERIOD'
export const FETCH_MODULATION_PERIOD_SUCCEEDED =
  'api/FETCH_MODULATION_PERIOD_SUCCEEDED'
export const FETCH_MODULATION_PERIOD_FAILED =
  'api/FETCH_MODULATION_PERIOD_FAILED'

export const FETCH_MODULATION_PERIODS = 'api/FETCH_MODULATION_PERIODS'
export const FETCH_MODULATION_PERIODS_SUCCEEDED =
  'api/FETCH_MODULATION_PERIODS_SUCCEEDED'
export const FETCH_MODULATION_PERIODS_FAILED =
  'api/FETCH_MODULATION_PERIODS_FAILED'

export const CREATE_MODULATION_PERIOD = 'api/CREATE_MODULATION_PERIOD'
export const CREATE_MODULATION_PERIOD_SUCCEEDED =
  'api/CREATE_MODULATION_PERIOD_SUCCEEDED'
export const CREATE_MODULATION_PERIOD_FAILED =
  'api/CREATE_MODULATION_PERIOD_FAILED'

export const UPDATE_MODULATION_PERIOD = 'api/UPDATE_MODULATION_PERIOD'
export const UPDATE_MODULATION_PERIOD_SUCCEEDED =
  'api/UPDATE_MODULATION_PERIOD_SUCCEEDED'
export const UPDATE_MODULATION_PERIOD_FAILED =
  'api/UPDATE_MODULATION_PERIOD_FAILED'

export const DELETE_MODULATION_PERIOD = 'api/DELETE_MODULATION_PERIOD'
export const DELETE_MODULATION_PERIOD_SUCCEEDED =
  'api/DELETE_MODULATION_PERIOD_SUCCEEDED'
export const DELETE_MODULATION_PERIOD_FAILED =
  'api/DELETE_MODULATION_PERIOD_FAILED'

// ------------------------------------
// Modulation periods Counters
// ------------------------------------
export const FETCH_MODULATION_PERIOD_COUNTER =
  'api/FETCH_MODULATION_PERIOD_COUNTER'
export const FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED =
  'api/FETCH_MODULATION_PERIOD_COUNTER_SUCCEEDED'
export const FETCH_MODULATION_PERIOD_COUNTER_FAILED =
  'api/FETCH_MODULATION_PERIOD_COUNTER_FAILED'

export const FETCH_MODULATION_PERIOD_COUNTERS =
  'api/FETCH_MODULATION_PERIOD_COUNTERS'
export const FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED =
  'api/FETCH_MODULATION_PERIOD_COUNTERS_SUCCEEDED'
export const FETCH_MODULATION_PERIOD_COUNTERS_FAILED =
  'api/FETCH_MODULATION_PERIOD_COUNTERS_FAILED'

export const FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION =
  'api/FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION'
export const FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED =
  'api/FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_SUCCEEDED'
export const FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_FAILED =
  'api/FETCH_MODULATION_PERIOD_COUNTERS_BY_LOCATION_FAILED'

export const FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP =
  'api/FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP'
export const FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_SUCCEEDED =
  'api/FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_SUCCEEDED'
export const FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_FAILED =
  'api/FETCH_MODULATION_PERIOD_COUNTERS_BY_MEMBERSHIP_FAILED'

export const UPDATE_MODULATION_PERIOD_COUNTER =
  'api/UPDATE_MODULATION_PERIOD_COUNTER'
export const UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED =
  'api/UPDATE_MODULATION_PERIOD_COUNTER_SUCCEEDED'
export const UPDATE_MODULATION_PERIOD_COUNTER_FAILED =
  'api/UPDATE_MODULATION_PERIOD_COUNTER_FAILED'

export const DELETE_MODULATION_PERIOD_COUNTER_FROM_STORE =
  'DELETE_MODULATION_PERIOD_COUNTER_FROM_STORE'
// ------------------------------------
// Modulation periods Counter Entries
// ------------------------------------
export const FETCH_MODULATION_PERIOD_COUNTER_ENTRIES =
  'api/FETCH_MODULATION_PERIOD_COUNTER_ENTRIES'
export const FETCH_MODULATION_PERIOD_COUNTER_ENTRIES_SUCCEEDED =
  'api/FETCH_MODULATION_PERIOD_COUNTER_ENTRIES_SUCCEEDED'
export const FETCH_MODULATION_PERIOD_COUNTER_ENTRIES_FAILED =
  'api/FETCH_MODULATION_PERIOD_COUNTER_ENTRIES_FAILED'
export const DELETE_MODULATION_PERIOD_COUNTER_ENTRY =
  'api/DELETE_MODULATION_PERIOD_COUNTER_ENTRY'
export const DELETE_MODULATION_PERIOD_COUNTER_ENTRY_SUCCEDED =
  'api/DELETE_MODULATION_PERIOD_COUNTER_ENTRY_SUCCEDED'
export const DELETE_MODULATION_PERIOD_COUNTER_ENTRY_FAILED =
  'api/DELETE_MODULATION_PERIOD_COUNTER_ENTRY_FAILED'

// ------------------------------------
// Monthly work time
// ------------------------------------
export const FETCH_MONTHLY_WORK_TIME = 'api/FETCH_MONTHLY_WORK_TIME'
export const FETCH_MONTHLY_WORK_TIME_SUCCEEDED =
  'api/FETCH_MONTHLY_WORK_TIME_SUCCEEDED'
export const FETCH_MONTHLY_WORK_TIME_FAILED =
  'api/FETCH_MONTHLY_WORK_TIME_FAILED'

// ------------------------------------
// Notes
// ------------------------------------
export const CREATE_NOTE = 'api/CREATE_NOTE'
export const CREATE_NOTE_SUCCEEDED = 'api/CREATE_NOTE_SUCCEEDED'
export const CREATE_NOTE_FAILED = 'api/CREATE_NOTE_FAILED'

export const UPDATE_NOTE = 'api/UPDATE_NOTE'
export const UPDATE_NOTE_SUCCEEDED = 'api/UPDATE_NOTE_SUCCEEDED'
export const UPDATE_NOTE_FAILED = 'api/UPDATE_NOTE_FAILED'

export const DELETE_NOTE = 'api/DELETE_NOTE'
export const DELETE_NOTE_SUCCEEDED = 'api/DELETE_NOTE_SUCCEEDED'
export const DELETE_NOTE_FAILED = 'api/DELETE_NOTE_FAILED'

// ------------------------------------
// Notifications
// ------------------------------------
export const FETCH_UNREAD_NOTIFICATIONS = 'api/FETCH_UNREAD_NOTIFICATIONS'
export const FETCH_UNREAD_NOTIFICATIONS_SUCCEEDED =
  'api/FETCH_UNREAD_NOTIFICATIONS_SUCCEEDED'
export const FETCH_UNREAD_NOTIFICATIONS_FAILED =
  'api/FETCH_UNREAD_NOTIFICATIONS_FAILED'

// ------------------------------------
// PadUsers
// ------------------------------------

export const FETCH_PAD_USERS = 'api/FETCH_PAD_USERS'
export const FETCH_PAD_USERS_SUCCEEDED = 'api/FETCH_PAD_USERS_SUCCEEDED'
export const FETCH_PAD_USERS_FAILED = 'api/FETCH_PAD_USERS_FAILED'

export const CREATE_PAD_USER = 'api/CREATE_PAD_USER'
export const CREATE_PAD_USER_SUCCEEDED = 'api/CREATE_PAD_USER_SUCCEEDED'
export const CREATE_PAD_USER_FAILED = 'api/CREATE_PAD_USER_FAILED'

export const UPDATE_PAD_USER = 'api/UPDATE_PAD_USER'
export const UPDATE_PAD_USER_SUCCEEDED = 'api/UPDATE_PAD_USER_SUCCEEDED'
export const UPDATE_PAD_USER_FAILED = 'api/UPDATE_PAD_USER_FAILED'

export const DELETE_PAD_USER = 'api/DELETE_PAD_USER'
export const DELETE_PAD_USER_SUCCEEDED = 'api/DELETE_PAD_USER_SUCCEEDED'
export const DELETE_PAD_USER_FAILED = 'api/DELETE_PAD_USER_FAILED'

// ------------------------------------
// Pay periods
// ------------------------------------
export const FETCH_PAY_PERIODS = 'api/FETCH_PAY_PERIODS'
export const FETCH_PAY_PERIODS_SUCCEEDED = 'api/FETCH_PAY_PERIODS_SUCCEEDED'
export const FETCH_PAY_PERIODS_FAILED = 'api/FETCH_PAY_PERIODS_FAILED'

export const FETCH_PAY_PERIOD = 'api/FETCH_PAY_PERIOD'
export const FETCH_PAY_PERIOD_SUCCEEDED = 'api/FETCH_PAY_PERIOD_SUCCEEDED'
export const FETCH_PAY_PERIOD_FAILED = 'api/FETCH_PAY_PERIOD_FAILED'

// ------------------------------------
// Payrolls
// ------------------------------------
export const FETCH_PAYROLL = 'api/FETCH_PAYROLL'
export const FETCH_PAYROLL_SUCCEEDED = 'api/FETCH_PAYROLL_SUCCEEDED'
export const FETCH_PAYROLL_FAILED = 'api/FETCH_PAYROLL_FAILED'

// ------------------------------------
// Payroll Software
// ------------------------------------
export const FETCH_PAYROLL_SOFTWARE = 'api/FETCH_PAYROLL_SOFTWARE'
export const FETCH_PAYROLL_SOFTWARE_SUCCEEDED =
  'api/FETCH_PAYROLL_SOFTWARE_SUCCEEDED'
export const FETCH_PAYROLL_SOFTWARE_FAILED = 'api/FETCH_PAYROLL_SOFTWARE_FAILED'

// ------------------------------------
// Payroll Software Configurations
// ------------------------------------
export const FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS =
  'api/FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS'
export const FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_SUCCEEDED =
  'api/FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_SUCCEEDED'
export const FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_FAILED =
  'api/FETCH_PAYROLL_SOFTWARE_CONFIGURATIONS_FAILED'

export const UPDATE_PAYROLL_SOFTWARE_CONFIGURATION =
  'api/UPDATE_PAYROLL_SOFTWARE_CONFIGURATION'
export const UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_SUCCEEDED =
  'api/UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_SUCCEEDED'
export const UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_FAILED =
  'api/UPDATE_PAYROLL_SOFTWARE_CONFIGURATION_FAILED'

// ------------------------------------
// Plannables
// ------------------------------------
export const FETCH_PLANNABLES_LIST = 'api/FETCH_PLANNABLES_LIST'
export const FETCH_PLANNABLES_LIST_SUCCEEDED =
  'api/FETCH_PLANNABLES_LIST_SUCCEEDED'
export const FETCH_PLANNABLES_LIST_FAILED = 'api/FETCH_PLANNABLES_LIST_FAILED'

// ------------------------------------
// Plannables overlap
// ------------------------------------

export const FETCH_PLANNABLE_OVERLAP = 'api/FETCH_PLANNABLE_OVERLAP'
export const FETCH_PLANNABLE_OVERLAP_SUCCEEDED =
  'api/FETCH_PLANNABLE_OVERLAP_SUCCEEDED'
export const FETCH_PLANNABLE_OVERLAP_FAILED =
  'api/FETCH_PLANNABLE_OVERLAP_FAILED'

// ------------------------------------
// Reports
// ------------------------------------
export const CREATE_REPORT = 'api/CREATE_REPORT'
export const CREATE_REPORT_SUCCEEDED = 'api/CREATE_REPORT_SUCCEEDED'
export const CREATE_REPORT_FAILED = 'api/CREATE_REPORT_FAILED'

export const CREATE_ALL_LOCATIONS_REPORT_FILE =
  'api/CREATE_ALL_LOCATIONS_REPORT_FILE'
export const CREATE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED =
  'api/CREATE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED'
export const CREATE_ALL_LOCATIONS_REPORT_FILE_FAILED =
  'api/CREATE_ALL_LOCATIONS_REPORT_FILE_FAILED'
export const FETCH_ALL_LOCATIONS_REPORT_FILES =
  'api/FETCH_ALL_LOCATIONS_REPORT_FILES'
export const FETCH_ALL_LOCATIONS_REPORT_FILES_SUCCEEDED =
  'api/FETCH_ALL_LOCATIONS_REPORT_FILES_SUCCEEDED'
export const FETCH_ALL_LOCATIONS_REPORT_FILES_FAILED =
  'api/FETCH_ALL_LOCATIONS_REPORT_FILES_FAILED'
export const DELETE_ALL_LOCATIONS_REPORT_FILE =
  'api/DELETE_ALL_LOCATIONS_REPORT_FILE'
export const DELETE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED =
  'api/DELETE_ALL_LOCATIONS_REPORT_FILE_SUCCEEDED'
export const DELETE_ALL_LOCATIONS_REPORT_FILE_FAILED =
  'api/DELETE_ALL_LOCATIONS_REPORT_FILE_FAILED'

export const FETCH_REPORT = 'api/FETCH_REPORT'
export const FETCH_REPORT_WITH_ROWS = 'api/FETCH_REPORT_WITH_ROWS'
export const FETCH_REPORT_ROW_SUCCEEDED = 'api/FETCH_REPORT_ROW_SUCCEEDED'
export const FETCH_REPORT_SUCCEEDED = 'api/FETCH_REPORT_SUCCEEDED'
export const FETCH_REPORT_FAILED = 'api/FETCH_REPORT_FAILED'

export const UPDATE_REPORT = 'api/UPDATE_REPORT'
export const UPDATE_REPORT_SUCCEEDED = 'api/UPDATE_REPORT_SUCCEEDED'
export const UPDATE_REPORT_FAILED = 'api/UPDATE_REPORT_FAILED'

// ------------------------------------
// Reports Files
// ------------------------------------
export const CREATE_REPORT_FILES = 'api/CREATE_REPORT_FILES'
export const CREATE_REPORT_FILES_SUCCEEDED = 'api/CREATE_REPORT_FILES_SUCCEEDED'
export const CREATE_REPORT_FILES_FAILED = 'api/CREATE_REPORT_FILES_FAILED'

export const FETCH_REPORT_FILES = 'api/FETCH_REPORT_FILES'
export const FETCH_REPORT_FILES_SUCCEEDED = 'api/FETCH_REPORT_FILES_SUCCEEDED'
export const FETCH_REPORT_FILES_FAILED = 'api/FETCH_REPORT_FILES_FAILED'

export const FETCH_REPORT_FILE_ROW_SUCCEEDED =
  'api/FETCH_REPORT_FILE_ROW_SUCCEEDED'
export const GENERATE_REPORT_FILE_SUCCEEDED =
  'api/GENERATE_REPORT_FILE_SUCCEEDED'

// ------------------------------------
// Rests
// ------------------------------------
export const FETCH_RESTS = 'api/FETCH_RESTS'
export const FETCH_RESTS_SUCCEEDED = 'api/FETCH_RESTS_SUCCEEDED'
export const FETCH_RESTS_FAILED = 'api/FETCH_RESTS_FAILED'

export const FETCH_REST = 'api/FETCH_REST'
export const FETCH_REST_SUCCEEDED = 'api/FETCH_REST_SUCCEEDED'
export const FETCH_REST_FAILED = 'api/FETCH_REST_FAILED'

export const CREATE_REST = 'api/CREATE_REST'
export const CREATE_REST_SUCCEEDED = 'api/CREATE_REST_SUCCEEDED'
export const CREATE_REST_FAILED = 'api/CREATE_REST_FAILED'

export const UPDATE_REST = 'api/UPDATE_REST'
export const UPDATE_REST_SUCCEEDED = 'api/UPDATE_REST_SUCCEEDED'
export const UPDATE_REST_FAILED = 'api/UPDATE_REST_FAILED'

export const DELETE_REST = 'api/DELETE_REST'
export const DELETE_REST_SUCCEEDED = 'api/DELETE_REST_SUCCEEDED'
export const DELETE_REST_FAILED = 'api/DELETE_REST_FAILED'

export const DELETE_RESTS_FROM_STORE = 'api/DELETE_RESTS_FROM_STORE'

// ------------------------------------
// Revenues
// ------------------------------------
export const FETCH_REVENUES = 'api/FETCH_REVENUES'
export const FETCH_REVENUES_SUCCEEDED = 'api/FETCH_REVENUES_SUCCEEDED'
export const FETCH_REVENUES_FAILED = 'api/FETCH_REVENUES_FAILED'

export const UPDATE_REVENUE = 'api/UPDATE_REVENUE'
export const UPDATE_REVENUE_SUCCEEDED = 'api/UPDATE_REVENUE_SUCCEEDED'
export const UPDATE_REVENUE_FAILED = 'api/UPDATE_REVENUE_FAILED'

// ------------------------------------
// Reward templates
// ------------------------------------
export const FETCH_REWARD_TEMPLATES = 'api/FETCH_REWARD_TEMPLATES'
export const FETCH_REWARD_TEMPLATES_SUCCEEDED =
  'api/FETCH_REWARD_TEMPLATES_SUCCEEDED'
export const FETCH_REWARD_TEMPLATES_FAILED = 'api/FETCH_REWARD_TEMPLATES_FAILED'

export const CREATE_REWARD_TEMPLATE = 'api/CREATE_REWARD_TEMPLATE'
export const CREATE_REWARD_TEMPLATE_SUCCEEDED =
  'api/CREATE_REWARD_TEMPLATE_SUCCEEDED'
export const CREATE_REWARD_TEMPLATE_FAILED = 'api/CREATE_REWARD_TEMPLATE_FAILED'

export const UPDATE_REWARD_TEMPLATE = 'api/UPDATE_REWARD_TEMPLATE'
export const UPDATE_REWARD_TEMPLATE_SUCCEEDED =
  'api/UPDATE_REWARD_TEMPLATE_SUCCEEDED'
export const UPDATE_REWARD_TEMPLATE_FAILED = 'api/UPDATE_REWARD_TEMPLATE_FAILED'

export const DELETE_REWARD_TEMPLATE = 'api/DELETE_REWARD_TEMPLATE'
export const DELETE_REWARD_TEMPLATE_SUCCEEDED =
  'api/DELETE_REWARD_TEMPLATE_SUCCEEDED'
export const DELETE_REWARD_TEMPLATE_FAILED = 'api/DELETE_REWARD_TEMPLATE_FAILED'

// ------------------------------------
// Rewards
// ------------------------------------

export const FETCH_REWARDS = 'api/FETCH_REWARDS'
export const FETCH_REWARDS_SUCCEEDED = 'api/FETCH_REWARDS_SUCCEEDED'
export const FETCH_REWARDS_FAILED = 'api/FETCH_REWARDS_FAILED'

export const FETCH_REWARDS_BY_MEMBERSHIP = 'api/FETCH_REWARDS_BY_MEMBERSHIP'
export const FETCH_REWARDS_BY_MEMBERSHIP_SUCCEEDED =
  'api/FETCH_REWARDS_BY_MEMBERSHIP_SUCCEEDED'
export const FETCH_REWARDS_BY_MEMBERSHIP_FAILED =
  'api/FETCH_REWARDS_BY_MEMBERSHIP_FAILED'

export const CREATE_REWARD = 'api/CREATE_REWARD'
export const CREATE_REWARD_SUCCEEDED = 'api/CREATE_REWARD_SUCCEEDED'
export const CREATE_REWARD_FAILED = 'api/CREATE_REWARD_FAILED'

export const UPDATE_REWARD = 'api/UPDATE_REWARD'
export const UPDATE_REWARD_SUCCEEDED = 'api/UPDATE_REWARD_SUCCEEDED'
export const UPDATE_REWARD_FAILED = 'api/UPDATE_REWARD_FAILED'

export const DELETE_REWARD = 'api/DELETE_REWARD'
export const DELETE_REWARD_SUCCEEDED = 'api/DELETE_REWARD_SUCCEEDED'
export const DELETE_REWARD_FAILED = 'api/DELETE_REWARD_FAILED'

// ------------------------------------
// Theoretical RestDuration
// ------------------------------------

export const FETCH_THEORETICAL_REST_DURATION =
  'api/FETCH_THEORETICAL_REST_DURATION'
export const FETCH_THEORETICAL_REST_DURATION_SUCCEEDED =
  'api/FETCH_THEORETICAL_REST_DURATION_SUCCEEDED'
export const FETCH_THEORETICAL_REST_DURATION_FAILED =
  'api/FETCH_THEORETICAL_REST_DURATION_FAILED'

// ------------------------------------
// Shifts
// ------------------------------------
export const FETCH_SHIFTS = 'api/FETCH_SHIFTS'
export const FETCH_SHIFTS_SUCCEEDED = 'api/FETCH_SHIFTS_SUCCEEDED'
export const FETCH_SHIFTS_FAILED = 'api/FETCH_SHIFTS_FAILED'

export const FETCH_SHIFT = 'api/FETCH_SHIFT'
export const FETCH_SHIFT_SUCCEEDED = 'api/FETCH_SHIFT_SUCCEEDED'
export const FETCH_SHIFT_FAILED = 'api/FETCH_SHIFT_FAILED'

export const FETCH_PAGINATED_SHIFTS = 'api/FETCH_PAGINATED_SHIFTS'
export const FETCH_PAGINATED_SHIFTS_SUCCEEDED =
  'api/FETCH_PAGINATED_SHIFTS_SUCCEEDED'
export const FETCH_PAGINATED_SHIFTS_FAILED = 'api/FETCH_PAGINATED_SHIFTS_FAILED'

export const CREATE_SHIFT = 'api/CREATE_SHIFT'
export const CREATE_SHIFT_SUCCEEDED = 'api/CREATE_SHIFT_SUCCEEDED'
export const CREATE_SHIFT_FAILED = 'api/CREATE_SHIFT_FAILED'

export const CREATE_BULK_SHIFTS = 'api/CREATE_BULK_SHIFTS'
export const CREATE_BULK_SHIFTS_SUCCEEDED = 'api/CREATE_BULK_SHIFTS_SUCCEEDED'
export const CREATE_BULK_SHIFTS_FAILED = 'api/CREATE_BULK_SHIFTS_FAILED'

export const VALIDATE_BULK_SHIFTS = 'api/VALIDATE_BULK_SHIFTS'
export const VALIDATE_BULK_SHIFTS_SUCCEEDED =
  'api/VALIDATE_BULK_SHIFTS_SUCCEEDED'
export const VALIDATE_BULK_SHIFTS_FAILED = 'api/VALIDATE_BULK_SHIFTS_FAILED'

export const INVALIDATE_BULK_SHIFTS = 'api/INVALIDATE_BULK_SHIFTS'
export const INVALIDATE_BULK_SHIFTS_SUCCEEDED =
  'api/INVALIDATE_BULK_SHIFTS_SUCCEEDED'
export const INVALIDATE_BULK_SHIFTS_FAILED = 'api/INVALIDATE_BULK_SHIFTS_FAILED'

export const UPDATE_SHIFT = 'api/UPDATE_SHIFT'
export const UPDATE_SHIFT_SUCCEEDED = 'api/UPDATE_SHIFT_SUCCEEDED'
export const UPDATE_SHIFT_FAILED = 'api/UPDATE_SHIFT_FAILED'

export const DELETE_SHIFT = 'api/DELETE_SHIFT'
export const DELETE_SHIFT_SUCCEEDED = 'api/DELETE_SHIFT_SUCCEEDED'
export const DELETE_SHIFT_FAILED = 'api/DELETE_SHIFT_FAILED'

export const DELETE_BULK_SHIFTS = 'api/DELETE_BULK_SHIFTS'
export const DELETE_BULK_SHIFTS_SUCCEEDED = 'api/DELETE_BULK_SHIFTS_SUCCEEDED'
export const DELETE_BULK_SHIFTS_FAILED = 'api/DELETE_BULK_SHIFTS_FAILED'

export const DELETE_SHIFTS_FROM_STORE = 'api/DELETE_SHIFTS_FROM_STORE'

// ------------------------------------
// Shift templates
// ------------------------------------
export const FETCH_SHIFT_TEMPLATES = 'api/FETCH_SHIFT_TEMPLATES'
export const FETCH_SHIFT_TEMPLATES_SUCCEEDED =
  'api/FETCH_SHIFT_TEMPLATES_SUCCEEDED'
export const FETCH_SHIFT_TEMPLATES_FAILED = 'api/FETCH_SHIFT_TEMPLATES_FAILED'

export const CREATE_SHIFT_TEMPLATE = 'api/CREATE_SHIFT_TEMPLATE'
export const CREATE_SHIFT_TEMPLATE_SUCCEEDED =
  'api/CREATE_SHIFT_TEMPLATE_SUCCEEDED'
export const CREATE_SHIFT_TEMPLATE_FAILED = 'api/CREATE_SHIFT_TEMPLATE_FAILED'

export const UPDATE_SHIFT_TEMPLATE = 'api/UPDATE_SHIFT_TEMPLATE'
export const UPDATE_SHIFT_TEMPLATE_SUCCEEDED =
  'api/UPDATE_SHIFT_TEMPLATE_SUCCEEDED'
export const UPDATE_SHIFT_TEMPLATE_FAILED = 'api/UPDATE_SHIFT_TEMPLATE_FAILED'

export const DELETE_SHIFT_TEMPLATE = 'api/DELETE_SHIFT_TEMPLATE'
export const DELETE_SHIFT_TEMPLATE_SUCCEEDED =
  'api/DELETE_SHIFT_TEMPLATE_SUCCEEDED'
export const DELETE_SHIFT_TEMPLATE_FAILED = 'api/DELETE_SHIFT_TEMPLATE_FAILED'

// ------------------------------------
// Subscription
// ------------------------------------
export const FETCH_SUBSCRIPTION = 'api/FETCH_SUBSCRIPTION'
export const FETCH_SUBSCRIPTION_FAILED = 'api/FETCH_SUBSCRIPTION_FAILED'

export const FETCH_INVOICES = 'api/FETCH_INVOICES'
export const FETCH_INVOICES_SUCCEEDED = 'api/FETCH_INVOICES_SUCCEEDED'
export const FETCH_INVOICES_FAILED = 'api/FETCH_INVOICES_FAILED'

export const UPDATE_SUBSCRIPTION = 'api/UPDATE_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION_SUCCEEDED = 'api/UPDATE_SUBSCRIPTION_SUCCEEDED'
export const UPDATE_SUBSCRIPTION_FAILED = 'api/UPDATE_SUBSCRIPTION_FAILED'

export const SWITCH_SUBSCRIPTION_TO_DIRECT_DEBIT =
  'api/SWITCH_SUBSCRIPTION_TO_DIRECT_DEBIT'

export const CANCEL_SUBSCRIPTION = 'api/CANCEL_SUBSCRIPTION'

export const REACTIVATE_SUBSCRIPTION = 'api/REACTIVATE_SUBSCRIPTION'

export const FETCH_SUBSCRIPTION_INVOICE = 'api/FETCH_SUBSCRIPTION_INVOICE'
export const FETCH_SUBSCRIPTION_INVOICE_SUCCEEDED =
  'api/FETCH_SUBSCRIPTION_INVOICE_SUCCEEDED'
export const FETCH_SUBSCRIPTION_INVOICE_FAILED =
  'api/FETCH_SUBSCRIPTION_INVOICE_FAILED'

// ------------------------------------
// Teams
// ------------------------------------
export const UPDATE_TEAM = 'api/UPDATE_TEAM'
export const UPDATE_TEAM_SUCCEEDED = 'api/UPDATE_TEAM_SUCCEEDED'
export const UPDATE_TEAM_FAILED = 'api/UPDATE_TEAM_FAILED'

export const DELETE_TEAM = 'api/DELETE_TEAM'
export const DELETE_TEAM_SUCCEEDED = 'api/DELETE_TEAM_SUCCEEDED'
export const DELETE_TEAM_FAILED = 'api/DELETE_TEAM_FAILED'

// ------------------------------------
// Timeoffs
// ------------------------------------
export const CREATE_TIMEOFF = 'api/CREATE_TIMEOFF'
export const CREATE_TIMEOFF_SUCCEEDED = 'api/CREATE_TIMEOFF_SUCCEEDED'
export const CREATE_TIMEOFF_FAILED = 'api/CREATE_TIMEOFF_FAILED'

export const UPDATE_TIMEOFF = 'api/UPDATE_TIMEOFF'
export const UPDATE_TIMEOFF_SUCCEEDED = 'api/UPDATE_TIMEOFF_SUCCEEDED'
export const UPDATE_TIMEOFF_FAILED = 'api/UPDATE_TIMEOFF_FAILED'

export const DELETE_TIMEOFF = 'api/DELETE_TIMEOFF'
export const DELETE_TIMEOFF_SUCCEEDED = 'api/DELETE_TIMEOFF_SUCCEEDED'
export const DELETE_TIMEOFF_FAILED = 'api/DELETE_TIMEOFF_FAILED'

// ------------------------------------
// Timesheets
// ------------------------------------

export const FETCH_TIMESHEETS = 'api/FETCH_TIMESHEETS'
export const FETCH_TIMESHEETS_SUCCEEDED = 'api/FETCH_TIMESHEETS_SUCCEEDED'
export const FETCH_TIMESHEETS_FAILED = 'api/FETCH_TIMESHEETS_FAILED'

// ------------------------------------
// Users
// ------------------------------------
export const FETCH_USER = 'api/FETCH_USER'
export const FETCH_USER_SUCCEEDED = 'api/FETCH_USER_SUCCEEDED'
export const FETCH_USER_FAILED = 'api/FETCH_USER_FAILED'

export const UPDATE_USER = 'api/UPDATE_USER'
export const UPDATE_USER_SUCCEEDED = 'api/UPDATE_USER_SUCCEEDED'
export const UPDATE_USER_FAILED = 'api/UPDATE_USER_FAILED'

// ------------------------------------
// User Toucan JWT
// ------------------------------------

export const FETCH_USER_TOUCAN_JWT = 'api/FETCH_USER_TOUCAN_JWT'
export const FETCH_USER_TOUCAN_JWT_SUCCEEDED =
  'api/FETCH_USER_TOUCAN_JWT_SUCCEEDED'
export const FETCH_USER_TOUCAN_JWT_FAILED = 'api/FETCH_USER_TOUCAN_JWT_FAILED'

// ------------------------------------
// Weeklyschedules
// ------------------------------------
export const FETCH_WEEKLYSCHEDULES = 'api/FETCH_WEEKLYSCHEDULES'
export const FETCH_WEEKLYSCHEDULES_SUCCEEDED =
  'api/FETCH_WEEKLYSCHEDULES_SUCCEEDED'
export const FETCH_WEEKLYSCHEDULES_FAILED = 'api/FETCH_WEEKLYSCHEDULES_FAILED'

export const FETCH_WEEKLYSCHEDULE = 'api/FETCH_WEEKLYSCHEDULE'
export const FETCH_WEEKLYSCHEDULE_SUCCEEDED =
  'api/FETCH_WEEKLYSCHEDULE_SUCCEEDED'
export const FETCH_WEEKLYSCHEDULE_FAILED = 'api/FETCH_WEEKLYSCHEDULE_FAILED'

export const DUPLICATE_WEEKLYSCHEDULE = 'api/DUPLICATE_WEEKLYSCHEDULE'
export const DUPLICATE_WEEKLYSCHEDULE_SUCCEEDED =
  'api/DUPLICATE_WEEKLYSCHEDULE_SUCCEEDED'
export const DUPLICATE_WEEKLYSCHEDULE_FAILED =
  'api/DUPLICATE_WEEKLYSCHEDULE_FAILED'

export const UPDATE_WEEKLYSCHEDULE = 'api/UPDATE_WEEKLYSCHEDULE'
export const UPDATE_WEEKLYSCHEDULE_SUCCEEDED =
  'api/UPDATE_WEEKLYSCHEDULE_SUCCEEDED'
export const UPDATE_WEEKLYSCHEDULE_FAILED = 'api/UPDATE_WEEKLYSCHEDULE_FAILED'

export const DELETE_WEEKLYSCHEDULE = 'api/DELETE_WEEKLYSCHEDULE'
export const DELETE_WEEKLYSCHEDULE_SUCCEEDED =
  'api/DELETE_WEEKLYSCHEDULE_SUCCEEDED'
export const DELETE_WEEKLYSCHEDULE_FAILED = 'api/DELETE_WEEKLYSCHEDULE_FAILED'

// ------------------------------------
// Sockets
// ------------------------------------
export const SOCKET_ACTION_PATTERN = /^socket\/(.+)$/
export const SOCKET_OPEN_CONNECTION = 'socket/OPEN_CONNECTION'

export const CONNECT_SOCKET = 'socket/CONNECT'
export const CONNECT_SOCKET_SUCCEEDED = 'socket/CONNECT_SUCCEEDED'
export const CONNECT_SOCKET_FAILED = 'socket/CONNECT_FAILED'
export const DISCONNECT_SOCKET = 'socket/DISCONNECT'

export const SUBSCRIBE_TO_CHANNEL = 'socket/SUBSCRIBE_TO_CHANNEL'
export const SUBSCRIBE_TO_DEFAULT_CHANNEL =
  'socket/SUBSCRIBE_TO_DEFAULT_CHANNEL'
export const SUBSCRIBE_TO_CHANNEL_SUCCEEDED =
  'socket/SUBSCRIBE_TO_CHANNEL_SUCCEEDED'
export const SUBSCRIBE_TO_CHANNEL_FAILED = 'socket/SUBSCRIBE_TO_CHANNEL_FAILED'

export const UNSUBSCRIBE_FROM_CHANNEL = 'socket/UNSUBSCRIBE_FROM_CHANNEL'

export const SEND_MESSAGE_TO_CHANNEL = 'socket/SEND_MESSAGE_TO_CHANNEL'

// ------------------------------------
// WsTickets
// ------------------------------------

export const FETCH_SOCKET_AUTHORIZATION_TICKET =
  'api/FETCH_SOCKET_AUTHORIZATION_TICKET'
export const FETCH_SOCKET_AUTHORIZATION_TICKET_SUCCEEDED =
  'api/FETCH_SOCKET_AUTHORIZATION_TICKET_SUCCEEDED'
export const FETCH_SOCKET_AUTHORIZATION_TICKET_FAILED =
  'api/FETCH_SOCKET_AUTHORIZATION_TICKET_FAILED'

// ------------------------------------
// Country Min Wages
// ------------------------------------
export const FETCH_COUNTRY_MIN_WAGES = 'api/FETCH_COUNTRY_MIN_WAGES'
export const FETCH_COUNTRY_MIN_WAGES_SUCCEEDED =
  'api/FETCH_COUNTRY_MIN_WAGES_SUCCEEDED'
export const FETCH_COUNTRY_MIN_WAGES_FAILED =
  'api/FETCH_COUNTRY_MIN_WAGES_FAILED'

// ------------------------------------
// Planning WS
// ------------------------------------
export const PLANNING_USER_CONTRACT_UPDATED_LOADING =
  'api/PLANNING_USER_CONTRACT_UPDATED_LOADING' as const
export const PLANNING_USER_CONTRACT_UPDATED =
  'api/PLANNING_USER_CONTRACT_UPDATED' as const
export const PLANNING_USER_CONTRACT_UPDATED_FAILED =
  'api/PLANNING_USER_CONTRACT_UPDATED_FAILED' as const

export * from './modules/memberships/actionTypes'
