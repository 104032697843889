import type { DocumentConvertSchema } from '@libs/data-access/queries'
import { wsServices } from '@libs/data-access/queries'

import type { ConvertDocumentOptions } from '../membership.documents.api'
import { convertDocument } from '../membership.documents.api'

const getConvertDocumentChannelPayload = (document_id: number) => {
  return {
    channel: 'EmployeeManagement::DocumentChannel',
    document_id
  } as const
}

const waitForJobCompleted = (type: string): Promise<DocumentConvertSchema> => {
  return new Promise(resolve => {
    wsServices.core.ws.onMessage((message: DocumentConvertSchema) => {
      if (message.type === type) {
        return resolve(message)
      }
    })
  })
}

export const useConvertDocumentSubscription =
  wsServices.core.subscription.create({
    subscribePayload: data =>
      getConvertDocumentChannelPayload(data.document_id),
    messages: {
      convertDocumentSucceeded:
        wsServices.core.subscription.defineMessageHandler<DocumentConvertSchema>(
          {
            type: 'emp/documentSignature/sagas/getDocumentB64'
          }
        )
    },
    emit: {
      convert: async (payload: ConvertDocumentOptions) => {
        await convertDocument(payload)
        return waitForJobCompleted('emp/documentSignature/sagas/getDocumentB64')
      },
      disconnect: (payload: ConvertDocumentOptions) => {
        return wsServices.core.ws.unsubscribe(
          getConvertDocumentChannelPayload(payload.pathParams.document_id)
        )
      }
    }
  })
