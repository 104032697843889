import type {
  DocumentTemplateAttributesSchema,
  DocumentTemplateEntrySchema,
  MissingInformationSchema
} from '@libs/data-access/entities'
import type { PaginationDocumentTemplateState } from '@spa/redux/emp/DocumentTemplate/modules/pagination'
import type { PaginationMissingInfoState } from '@spa/redux/emp/MissingInformation/modules/pagination'

import type { DeleteRoute, GetRoute, PatchRoute, PostRoute } from '../../types'

export const empRoutes = {
  // Missing Information
  getMissingInformation: {
    method: 'GET',
    path: '/employee_management/incomplete_files'
  },
  getPaginatedMissingInformation: {
    method: 'GET',
    path: '/employee_management/incomplete_files'
  },
  getMissingInformationByMembershipId: {
    method: 'GET',
    path: '/employee_management/incomplete_files'
  },
  getSsrRegisteryFile: {
    method: 'GET',
    path: '/employee_management/single_staff_registers'
  },

  // Document template
  fetchDocumentTemplateAttributes: {
    method: 'GET',
    path: '/employee_management/document_templates/available_attributes'
  },
  fetchDocumentTemplate: {
    method: 'GET',
    path: '/employee_management/document_templates'
  },
  postDocumentTemplate: {
    method: 'POST',
    path: '/employee_management/document_templates'
  },
  fillDocumentTemplate: {
    method: 'POST',
    path: '/employee_management/document_templates/:document_template_id/fill'
  },
  updateDocumentTemplate: {
    method: 'PATCH',
    path: '/employee_management/document_templates/:document_template_id'
  },
  deleteDocumentTemplate: {
    method: 'DELETE',
    path: '/employee_management/document_templates/:document_template_id'
  }
} as const

export type EMPRoutes = {
  getMissingInformation: GetRoute<
    [],
    {
      location_id?: number
      team_id?: number
      dpae?: boolean
      ssr?: boolean
    },
    { resources: MissingInformationSchema[]; meta: PaginationMissingInfoState }
  >
  getPaginatedMissingInformation: GetRoute<
    [],
    {
      location_id?: number
      team_id?: number
      page?: number
      per_page?: number
      dpae?: boolean
      ssr?: boolean
    },
    { resources: MissingInformationSchema[]; meta: PaginationMissingInfoState }
  >
  getMissingInformationByMembershipId: GetRoute<
    [],
    { membership_id: number; dpae?: boolean; ssr?: boolean },
    { resources: MissingInformationSchema; meta: PaginationMissingInfoState }
  >
  getSsrRegisteryFile: GetRoute<
    [],
    {
      location_id?: number
    },
    any
  >

  // Document template
  fetchDocumentTemplateAttributes: GetRoute<
    [],
    {},
    { attributes: DocumentTemplateAttributesSchema }
  >
  fetchDocumentTemplate: GetRoute<
    [],
    {
      page: number
      per_page: number
    },
    {
      resources: DocumentTemplateEntrySchema[]
      meta: PaginationDocumentTemplateState
    }
  >
  postDocumentTemplate: PostRoute<
    [],
    {
      title: string
      document: File
    },
    DocumentTemplateEntrySchema
  >
  fillDocumentTemplate: PostRoute<
    ['document_template_id'],
    {
      contract_id: number
      output_name: string
      convert_pdf: boolean
      amendment_id?: number
    }
  >
  updateDocumentTemplate: PatchRoute<
    ['document_template_id'],
    {
      title: string
      document: File
    },
    DocumentTemplateEntrySchema
  >
  deleteDocumentTemplate: DeleteRoute<['document_template_id'], {}>
}
