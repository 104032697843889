import type { PropsWithChildren } from 'react'
import React, { createContext, useContext, useMemo, useState } from 'react'

export type DesignSystemConfig = {
  timezone: string
  language: string
}

type InternalDesignSystemContextType = {
  config: DesignSystemConfig
  setConfig: (config: DesignSystemConfig) => void
}

const InternalDesignSystemContext = createContext<
  InternalDesignSystemContextType | undefined
>(undefined)

export const InternalDesignSystemProvider: React.FC<PropsWithChildren<{}>> = ({
  children
}) => {
  const [config, setConfig] = useState({
    timezone: 'UTC',
    language: new Intl.Locale(navigator.language).region || 'FR'
  })

  const memoValue = useMemo(() => {
    return { config, setConfig }
  }, [config, setConfig])

  return (
    <InternalDesignSystemContext.Provider value={memoValue}>
      {children}
    </InternalDesignSystemContext.Provider>
  )
}

export const useInternalDesignSystem = (): InternalDesignSystemContextType => {
  const context = useContext(InternalDesignSystemContext)
  if (!context) {
    throw new Error(
      'useInternalDesignSystem must be used within an InternalDesignSystemProvider'
    )
  }
  return context
}
