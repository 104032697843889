export const membershipDocumentsCacheKeys = {
  fetch_membership_documents: (queryParams = {}) =>
    ['fetch_membership_documents', queryParams] as const,
  delete_membership_documents: (queryParams = {}) =>
    ['delete_membership_documents', queryParams] as const,
  post_legacy_membership_documents: (queryParams = {}) =>
    ['post_legacy_membership_documents', queryParams] as const,
  post_membership_documents_signature: (queryParams = {}) =>
    ['post_membership_documents_signature', queryParams] as const
}
