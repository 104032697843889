import { useMergedScreenProps } from '@libs/ui/ds/lib/theme'
import {
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import { cva } from 'class-variance-authority'
import { useRef, type FC } from 'react'

import { tableFilterAdapter } from '../services/filters/tableFilterAdapter'
import { useTableGlobalFilterState } from '../services/filters/useTableGlobalFilterState'
import { getDefaultRowId } from '../services/getDefaultRowId'
import { useCreateColumns } from '../services/useCreateColumns'
import useSelectAllFromPage from '../services/useSelectAllFromPage'
import { useSortingState } from '../services/useSortingState'

import type { TableProps } from './Table.types'
import { TableEmptyContent } from './compound/TableEmptyContent'
import { TableErrorContent } from './compound/TableErrorContent'
import { TableFooter } from './subs/TableFooter'
import { TableHeader } from './subs/TableHeader/TableHeader'
import { TableHorizontalShadow } from './subs/TableHorizontalShadow/TableHorizontalShadow'
import { TablePagination } from './subs/TablePagination/TablePagination'
import { TableRow } from './subs/TableRow/TableRow'
import { TableStatesContent } from './subs/TableStates/TableStatesContent'
import { TableStatesWrap } from './subs/TableStates/TableStatesWrap'

const tableCVA = {
  root: cva(['relative text-xs'], {
    variants: {
      horizontalScroll: {
        true: 'overflow-x-auto',
        false: 'w-full'
      }
    }
  }),
  table: cva(['relative w-full border-collapse overflow-x-auto'], {
    variants: {
      tableLayout: {
        fixed: 'table-fixed',
        auto: 'table-auto'
      }
    }
  })
}

const BaseTable = <T extends unknown>({
  /**
   * Core props
   */
  data,
  columns,
  className,
  screens,
  /**
   * State props
   */
  manualSorting = false,
  sort,
  pagination,
  selection = { state: {} },
  globalFilter,
  states = {},
  /**
   * Click props
   */
  onRowClick,
  clickableRowsFilter,
  /**
   * Display props
   */
  headerPosition,
  cellsContent = 'ellipsis',
  cellsPaddingSize = 'm',
  showHeader = true,
  manualFiltering = false,
  getRowId = getDefaultRowId,
  showFooter,
  tableLayout = 'fixed',

  /**
   * Fixed and horizontal scroll columns
   */
  horizontalScroll = false,
  columnPinningState,

  ...other
}: TableProps<T>) => {
  const tableRef = useRef<HTMLDivElement>(null)
  const mergedProps = useMergedScreenProps(
    {
      showHeader,
      cellsContent,
      showFooter,
      headerPosition,
      tableLayout
    },
    // @ts-ignore TO DO: fix (strictNullChecks errors) (https://snapshiftapp.atlassian.net/browse/COP-333)
    screens
  )

  const sortingValue = sort?.[0] ? [sort[0]] : []

  const { sorting, setSorting } = useSortingState({
    defaultValue: sortingValue,
    onSortingChange: sort?.onChange
  })

  const { filter, setFilter } = useTableGlobalFilterState(globalFilter)

  const allColumns = useCreateColumns<T>({
    columns,
    selection,
    data
  })

  const table = useReactTable<T>({
    data,
    columns: allColumns,
    state: {
      rowSelection: selection?.state,
      sorting,
      globalFilter: filter,
      ...(columnPinningState && { columnPinning: columnPinningState })
    },
    /**
     * core
     */
    getRowId,
    getSortedRowModel: getSortedRowModel(),

    /**
     * filters
     */
    onGlobalFilterChange: setFilter,
    globalFilterFn: tableFilterAdapter,
    getFilteredRowModel: getFilteredRowModel(),
    manualFiltering,
    /**
     * sorting
     */
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting,

    /**
     * selection
     */
    enableRowSelection: selection?.rowEnable || false,
    onRowSelectionChange: selection?.onChange,

    /*
     * Defining default size column to ensure the customization works
     */
    defaultColumn: {
      size: 0,
      minSize: 0
    }
  })

  useSelectAllFromPage({ selection, table })

  return (
    <TableHorizontalShadow
      horizontalScroll={horizontalScroll}
      table={table}
      tableRef={tableRef}
    >
      <div
        ref={tableRef}
        {...other}
        className={tableCVA.root({
          horizontalScroll,
          className
        })}
      >
        <table
          className={tableCVA.table({
            tableLayout: mergedProps.tableLayout
          })}
        >
          <TableHeader
            loading={states.loading}
            showHeader={mergedProps.showHeader}
            headerPosition={mergedProps.headerPosition}
            table={table}
            cellsPaddingSize={cellsPaddingSize}
          />
          <TableStatesWrap data={data} states={states}>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <TableRow
                  loading={states.loading}
                  onClick={onRowClick}
                  clickableRowsFilter={clickableRowsFilter}
                  cellsContent={mergedProps.cellsContent}
                  cellsPaddingSize={cellsPaddingSize}
                  key={row.id}
                  row={row}
                  data-testid='table-row'
                />
              ))}
            </tbody>
          </TableStatesWrap>
          <TableFooter showFooter={mergedProps.showFooter} table={table} />
        </table>

        <TableStatesContent data={data} states={states} />
      </div>

      <TablePagination data={data} states={states} pagination={pagination} />
    </TableHorizontalShadow>
  )
}

type CompoundTableType = {
  ErrorContent: typeof TableErrorContent
  EmptyContent: typeof TableEmptyContent
}

export const Table = BaseTable as FC<TableProps> & CompoundTableType
Table.displayName = 'Table'
Table.ErrorContent = TableErrorContent
Table.EmptyContent = TableEmptyContent

export * from '../services/useTableState'
export * from '../services/createColumnHelper'
export * from './Table.types'
