import { httpServices } from '../../apis'
import type { SafeURLPath } from '../../apis/http/interfaces/HttpClient.interface'

import type { FileType } from './blobService.constants'

class BlobService {
  /**
   * Transform raw Blob data to a window Object.url
   */
  createBlobUrl(binaryFile: BlobPart, opts?: { type?: FileType }) {
    const blob = new Blob([binaryFile], opts)
    return window.URL.createObjectURL(blob)
  }

  /**
   * Trigger http fetch to retrive binary data
   * and returns a window Object.url
   */
  async fetchAndCreateBlobUrl(url: string) {
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error(`Error fetching the file: ${response.statusText}`)
    }
    const blob = await response.blob()
    return window.URL.createObjectURL(blob)
  }

  /* Similar to fetchAndCreateBlobUrl , except that we use httpServices.core.get instead of fetch. It allows preserving
    the authorization
   */
  async fetchAndCreateBlobUrlOverHttpServices(path: SafeURLPath) {
    const response = await httpServices.core.get(path, { responseType: 'blob' })

    return blobService.createBlobUrl(response.data)
  }

  /**
   * Convert an array of image URL to an array of object File
   * @param urls
   */
  async convertImageSourceToFile(urls: string[]) {
    if (!Array.isArray(urls)) {
      return []
    }

    const promises = urls.map(async (url, index) => {
      const response = await fetch(url)
      const blob = await response.blob()

      return new File([blob], `file-${index + 1}`, { type: blob.type })
    })

    return Promise.all(promises)
  }

  /**
   * Open in a new browser tab an Object.url built from a Blob
   */
  openInNewTab(blobUrl: string) {
    this.createAndOpenAnchor({ url: blobUrl })
  }

  /**
   * Trigger the browser download of an Object.url built from a Blob
   */
  async download(blobUrl: string, opts?: { filename?: string }) {
    this.createAndOpenAnchor({
      url: blobUrl,
      filename: opts?.filename,
      download: true,
      // We need the target "_self" so it's not blocked by the popup blocker
      target: '_self'
    })
    this.revokeBlobUrl(blobUrl)
  }

  /**
   * Trigger the browser download of an Object.url built from a Blob
   */
  async downloadPDF(blobUrl?: string | null, opts?: { filename?: string }) {
    if (!blobUrl) {
      return
    }
    this.createAndOpenAnchor({
      url: blobUrl,
      filename: opts?.filename || 'combo-file.pdf',
      target: '_self'
    })
    this.revokeBlobUrl(blobUrl)
  }

  /**
   * Trigger the browser download of an Object.url built from a Blob
   */
  async downloadZIP(blobUrl: string, opts?: { filename?: string }) {
    this.createAndOpenAnchor({
      url: blobUrl,
      filename: opts?.filename ? `${opts?.filename}.zip` : 'combo-file.zip',
      target: '_self'
    })
    this.revokeBlobUrl(blobUrl)
  }

  /**
   * Util function to open Object.url programatically in the browser
   */
  private createAndOpenAnchor(opts: {
    target?: '_blank' | '_self'
    url: string
    filename?: string
    download?: boolean
  }) {
    const anchor = document.createElement('a')
    anchor.target = opts?.target || '_blank'
    anchor.href = opts.url
    if (opts?.filename) {
      anchor.download = opts?.filename
    } else if (opts?.download) {
      anchor.toggleAttribute('download', true)
    }

    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  }

  /**
   * Revoke blob url, so the browser will lose object reference
   */
  private revokeBlobUrl(url: string) {
    setTimeout(() => {
      window.URL.revokeObjectURL(url)
    }, 100)
  }
}

export const blobService = new BlobService()
